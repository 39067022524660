import FormsService from '@/services/forms-service'
import $http from '@/libs/axios'

type SortType = {
    field: string;
    type: 'asc' | 'desc';
}

type ServerParams = {
    columnFilters: {[key: string]: any};
    sort: Array<SortType> | SortType;
    page: number;
    perPage: number;
}

class TableFetchService {
  static async Fetch(url: string, params: ServerParams) {
    const query: any = {
      page: params.page,
      per_page: params.perPage,
    }
    if (params.columnFilters) {
      Object.keys(params.columnFilters).forEach(key => {
        if (params.columnFilters[key] !== '' && params.columnFilters[key] !== null) {
          query[key] = params.columnFilters[key]
        }
      })
    }
    if (params.sort) {
      const sort = Array.isArray(params.sort) ? params.sort : [params.sort]
      sort
        .filter(s => !!s)
        .forEach(s => {
          if (s.field) {
            query['sort[]'] = `${s.field}|${s.type || 'asc'}`
          }
        })
    }
    const q = FormsService.prepareQueryString(query, true)
    return (await $http.get(`${url}?${q}`)).data
  }
}

export default TableFetchService
