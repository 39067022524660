<template>
  <empty-card
    table
    :title="title"
  >
    <template slot="title">
      <div class="d-flex align-items-center">
        <slot name="buttons" />
        <b-button
          v-if="showFilterButton"
          variant="primary"
          @click="showFilters = !showFilters"
        >
          <FeatherIcon icon="FilterIcon" />
        </b-button>
      </div>
    </template>
    <b-collapse
      id="collapse-filters"
      v-model="showFilters"
      class="mt-2"
    >
      <TableFilters
        v-if="config.filters && config.filters.length"
        ref="filters"
        v-model="queries"
        :filters="config.filters"
        @submit="loadItems"
      >
        <template slot="extra-filters">
          <slot name="filters" />
        </template>
      </TableFilters>
    </b-collapse>
    <vue-good-table
      ref="table"
      :columns="columns"
      :rows="rows"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :sort-options="{
        enabled: false,
      }"
      :search-options="searchOptions"
      :select-options="selectOptions"
      style-class="vgt-table"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-selected-rows-change="$emit('select-changed',$event)"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      v-on="config.rowClickable ? {'on-row-click': (props) => $emit('on-row-click', props),'on-cell-click': (props) => $emit('on-cell-click', props), 'on-row-dblclick': (props) => $emit('on-row-dblclick', props)} : {}"
    >

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          {{ $t(props.column.label).toUpperCase() }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: slots -->
        <div v-if="props.column.field !== 'action'">
          <slot
            :name="`${props.column.field}-column`"
            :props="{row: props.row, formatted: props.formattedRow[props.column.field],index:props.index }"
          >
            <div :class="{'d-flex justify-content-center align-items-center':(props.column.center)}">
              {{ props.formattedRow[props.column.field] }}
            </div>
          </slot>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.column.actionType==='dropdown'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              :right="$store.getters['appConfig/isRTL']"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <template v-for="(action, idx) in config.actions">
                <b-dropdown-item
                  v-if="!action.hide || (typeof action.hide != 'function') || !action.hide(props.row)"
                  :key="idx"
                  :disabled="action.disabled && (typeof action.disabled === 'function') && action.disabled(props.row)"
                  @click.stop="$emit(action.eventName, props)"
                >
                  <feather-icon
                    v-if="action.icon"
                    :icon="action.icon"
                    class="mr-50"
                  />
                  <span>{{ $t(action.label) }}</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </span>
          <div
            v-else
            class="d-flex align-items-center"
          >
            <template v-for="(action, idx) in config.actions">
              <b-button
                v-if="!action.hide || (typeof action.hide != 'function') || !action.hide(props.row)"
                :key="idx"
                :variant="action.variant || 'light'"
                size="sm"
                style="margin-left: 5px"
                :disabled="action.disabled && (typeof action.disabled === 'function') && action.disabled(props.row)"
                :class="{'btn-icon':!action.label}"
                @click.stop="$emit(action.eventName, props)"
              >
                <feather-icon
                  v-if="action.icon"
                  :icon="action.icon"
                  :class="{'mr-50': !!action.label}"
                />
                <span v-if="action.label">{{ $t(action.label) }}</span>

              </b-button>
            </template>
          </div>
        </span>
        <template
          slot="actions"
          slot-scope="props"
        >
          <div class="no-wrap d-flex align-items-center justify-content-between">
            <div class="text-center w-100 ok-datatable-row-actions">
              <slot
                name="actions"
                :props="props"
              />
            </div>
          </div>
        </template>
      </template>

      <template slot="emptystate">
        <slot name="empty-state">
          <div class="text-center">
            {{ $t('pagination.no-data-available') }}
          </div>
        </slot>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap table-footer-records">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('pagination.show') }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="perPageOptions"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap   d-none d-sm-block"> {{ $t('pagination.showing',{from:pagination.from, to:pagination.to, total:pagination.total}) }} </span>
          </div>
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  :icon="direction?'ChevronRightIcon':'ChevronLeftIcon'"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  :icon="direction?'ChevronLeftIcon':'ChevronRightIcon'"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <template slot="loadingContent">
        <div>
          <h4 class="font-weight-bold">
            {{ $t('pagination.loading') }} ...
          </h4>
          <b-spinner type="grow" />
        </div>
      </template>
    </vue-good-table>
  </empty-card>
</template>

<script>
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BSpinner, BButton, BCollapse,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import get from 'lodash/get'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import TableFetchService from '../../../../services/TableFetchService'

export default {
  name: 'OkTable',
  components: {
    EmptyCard,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BButton,
    BCollapse,
    TableFilters: () => import(/* webpackChunkName: "ok-table-filters" */ './TableFilters'),
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    showColumns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      hiddenColumns: [],
      totalRecords: 0,
      pageLength: this.config.paginationOptions?.perPage || 10,
      dir: false,
      currentPage: 1,
      rows: this.data,
      isLoading: false,
      searchTerm: '',
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      queries: [],
      showFilters: false,
      pagination: {
        current: 1,
        from: 1,
        last_page: 1,
        per_page: '10',
        size: '10',
        to: 1,
        total: 1,
      },
      websocketInstance: null,
      currentWS: null,
      socketClosed: false,
      socketPingInterval: null,
    }
  },
  computed: {
    direction() {
      if (this.$store.getters['appConfig/isRTL']) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    columns() {
      const columns = [...(this.config?.columns ?? [])]
      if (this.config.actions?.length > 0) {
        const col = {
          label: 'forms.actions',
          field: 'action',
          sortable: false,
        }
        if (this.config.actionFirst) columns.unshift(col)
        else columns.push(col)
      }
      return columns
    },
    searchOptions() {
      return {
        ...(this.config?.searchOptions ?? {}),
        externalQuery: this.searchTerm,
      }
    },
    perPageOptions() {
      return this.config.paginationOptions?.perPageOptions ?? [5, 10, 25, 50]
    },
    showFilterButton() {
      return this.config.filters?.length && !this.config.alwaysShowFilters
    },
    selectOptions() {
      if (!this.config.selectOptions) return []

      const options = cloneDeep(this.config.selectOptions)
      if (this.$te(options?.selectionText)) options.selectionText = this.$t(options.selectionText)
      if (this.$te(options?.clearSelectionText)) options.clearSelectionText = this.$t(options.clearSelectionText)
      return options
    },
  },
  watch: {
    showColumns(val) {
      this.toggleColumn(val)
    },
    data(rows) {
      if (this.config?.mode === 'local') {
        this.rows = rows
      }
    },
  },
  mounted() {
    const { mode } = this.config

    if (mode === 'remote') {
      this.loadItems()
    }

    this.showFilters = this.config.alwaysShowFilters ?? false
  },
  methods: {
    refresh() {
      window.location.reload()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    getFilters() {
      return this.queries
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params ?? [],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    reload() {
      this.loadItems()
    },
    addFiltersAsParameters(filters) {
      this.updateParams({ columnFilters: filters })
    },
    toggleColumn(visibles) {
      if (this.config.chooser && visibles.length) {
        this.columns.forEach((col, index) => {
          this.$set(this.columns[index], 'hidden', !visibles.some(ev => ev.field === col.field))
        })
      }
    },
    loadItems(filters = null) {
      if (this.config.mode === 'local') {
        return
      }

      const { url } = this.config
      /* if (this.config.attachPageId) {
        const pageId = this.$store.getters['']
        url = `${url}/${pageId}`
      } */
      this.isLoading = true
      if (filters != null) this.addFiltersAsParameters(filters)
      this.$emit('table:fetch')
      TableFetchService.Fetch(url, this.serverParams)
        .then(response => {
          const paginationPath = this.config.paginationPath || ''
          this.totalRecords = get(response, `${paginationPath}pagination.total`, 0)
          this.$emit('update:total', this.totalRecords)
          this.pagination = get(response, `${paginationPath}pagination` || {}, {})
          this.rows = get(response, this.config.rowsPath || '', [])
          const extra = this.config.append_extra
          if (extra && response[extra]) {
            const owner = get(response, extra || '', [])
            if (owner instanceof Array) this.rows = owner.concat(this.rows)
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            this.$notifyError(error)
          }
        })
        .finally(() => { this.isLoading = false })
    },
    toggleLoading(loading) {
      this.isLoading = loading ?? !this.isLoading
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive{
  min-height: 215px;
}
</style>
